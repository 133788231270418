import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router';

// Sentry
// @ts-expect-error -- enabling strict mode
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    // @ts-expect-error -- enabling strict mode
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // @ts-expect-error -- enabling strict mode
    tracePropagationTargets: process.env.REACT_APP_API_ORIGIN
      ? // @ts-expect-error -- enabling strict mode
        [new RegExp(process.env.REACT_APP_API_ORIGIN)]
      : undefined,
    tracesSampleRate:
      // @ts-expect-error -- enabling strict mode
      process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE != null
        ? // @ts-expect-error -- enabling strict mode
          parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
        : undefined,
    // @ts-expect-error -- enabling strict mode
    environment: process.env.REACT_APP_ENVIRONMENT,
    // @ts-expect-error -- enabling strict mode
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}
