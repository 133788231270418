import { useCallback, useSyncExternalStore } from 'react';

const getServerSnapshot = () => {
  throw Error('useMediaQuery is a client-only hook');
};

/**
 * The useMediaQuery hook leverages the window.matchMedia API to subscribe to CSS media query changes, thereby providing
 * real-time responsiveness to dynamic changes in the viewport or screen orientation.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @see https://github.com/uidotdev/usehooks/blob/f2794aa6799b129c77ddc914d85e5222d8488796/index.js#L760
 * @see https://v3.tailwindcss.com/docs/theme#screens
 *
 * @example
 * const isSmallScreen = useMediaQuery('(min-width: 640px)');
 * const isMediumScreen = useMediaQuery('(min-width: 768px)');
 * const isLargeScreen = useMediaQuery('(min-width: 1024px)');
 */
export function useMediaQuery(query: string): boolean {
  const subscribe = useCallback(
    (callback: () => void) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query],
  );

  const getSnapshot = useCallback(() => window.matchMedia(query).matches, [query]);

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}
