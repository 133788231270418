import type { ReactNode } from 'react';
import { Suspense } from 'react';
import Lottie from 'react-lottie';
import { Outlet } from 'react-router';

import { Footer } from '../../components/Footer/Footer';
import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';
import animationData from '../../components/LottieAnimations/pledgeLoadingPurple.json';
import animationDataDark from '../../components/LottieAnimations/pledgeLoadingDark.json';
import { useTheme } from '../../hooks/useTheme';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface OneColumnLayoutProps {
  children?: React.ReactNode;
  footer?: ReactNode;
}

export function OneColumnLayout({ children, footer }: OneColumnLayoutProps) {
  const [theme] = useTheme();

  return (
    <div id="OneColumnLayout" className="mx-auto flex min-h-full max-w-[75rem] flex-col gap-4 px-5 pt-4 pb-6">
      <header className="flex h-12 items-center">
        <a href="/" aria-label="Home">
          <Lottie
            options={{
              ...defaultLottieOptions,
              animationData: theme === 'dark' ? animationDataDark : animationData,
            }}
            isClickToPauseDisabled={true}
            height={28}
            width={133}
          />
        </a>
      </header>
      <main className="grow">
        <Suspense fallback={<LoadingFullScreen />}>{children ?? <Outlet />}</Suspense>
      </main>
      <footer>{footer ?? <Footer />}</footer>
    </div>
  );
}
