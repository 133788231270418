import { useMemo } from 'react';
import { useLocation } from 'react-router';

// Matches /test but not /test-* (to avoid mathcing /test-data)
export const checkIsTestModeRegex = /^\/test(?!-)/;

function isTestMode(pathname: string): boolean {
  // If pathname is not defined, we are not in test mode
  if (!pathname) {
    return false;
  }
  return checkIsTestModeRegex.test(pathname);
}

export function useIsTestMode() {
  const { pathname } = useLocation();
  const res = useMemo(() => isTestMode(pathname), [pathname]);
  return res;
}
