import { useFlag } from '@unleash/proxy-client-react';

import { useStoredValue } from './useStoredValue';
import { useMediaQuery } from './useMediaQuery';

export function useTheme(): [
  theme: 'light' | 'dark',
  setThemePreference: (theme: 'light' | 'dark' | 'system') => void,
  themePreference: 'light' | 'dark' | 'system',
] {
  const isThemePreferenceEnabled = useFlag('theme-preference');
  const [themePreference, setThemePreference] = useStoredValue('app.settings.theme', 'light');
  const systemPrefersDark = useMediaQuery('(prefers-color-scheme: dark)');

  if (
    !isThemePreferenceEnabled ||
    (themePreference !== 'light' && themePreference !== 'dark' && themePreference !== 'system')
  ) {
    return ['light', setThemePreference, 'light'] as const;
  }

  if (themePreference === 'system') {
    return [systemPrefersDark ? 'dark' : 'light', setThemePreference, themePreference] as const;
  }

  return [themePreference, setThemePreference, themePreference] as const;
}
